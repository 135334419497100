<template>
  <div class="head">
    <div class="content">
      <div class="nav">
        <a-menu class="nav-content" mode="horizontal" :selectedKeys="['index']">
          <a-menu-item key="index">
            <span class="nav-item"
              >{{ home[langNamesEnum[languageActive]] }}
            </span>
          </a-menu-item>
          <template v-for="(item, index) in dataSource" :key="index">
            <a-menu-item :key="index" v-if="item.checked">
              <a class="nav-item" target="_blank" :href="item.url"
                >{{ item.langNamesObj[langNamesEnum[languageActive]] }}
                <span class="label new" v-if="item.labelId == 1">
                  <i
                    >{{ $t("CM_Newest")
                    }}<!-- 最新 --></i
                  >
                </span>
                <span class="label hot" v-if="item.labelId == 2">
                  <i
                    >{{ $t("hottest")
                    }}<!-- 最热 --></i
                  >
                </span>
              </a>
            </a-menu-item>
          </template>
        </a-menu>
      </div>
      <div class="info" v-if="languageLabels.length > 1">
        <div class="user">
          <GlobalOutlined class="icon" />
          <div class="user-box">
            <ul>
              <li
                @click.stop="langChange(item)"
                v-for="item in languageLabels"
                :key="item.value"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default {
  name: "Menu",
  props: {
    languageLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const langNamesEnum = {
      "zh-CN": "zh_CN",
      "zh-TW": "zh_TW",
      "en-US": "en_US",
    };
    const languageActive = computed(() => store.getters.lang);
    const langChange = (item) => {
      if (item.value == languageActive.value) return false;
      proxy.$i18n.locale = item.value;
      store.dispatch("setLang", item.value);
      location.reload();
    };

    // 导航
    const home = {
      zh_CN: "首页",
      zh_TW: "首頁",
      en_US: "Home",
    };

    return {
      langNamesEnum,
      languageActive,
      langChange,
      home,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  max-width: 1920px;
  margin: 0 auto;
  height: 65px;
  background-color: @color-theme;
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .nav {
      width: calc(100% - 60px);
      &-content {
        border-bottom: none;
        background-color: @color-theme;
        line-height: 65px;
        color: #fff;
        ::v-deep(.ant-menu-item) {
          padding: 0 20px;
          font-size: 16px;
          top: 0;
          &::after {
            bottom: 4px;
            left: calc(50% - 16px);
            width: 32px;
            border-bottom: 4px solid transparent;
            border-radius: 4px;
          }
          .nav-item {
            color: #fff;
            font-size: 16px;
          }
          &-selected {
            &::after {
              border-bottom: 4px solid #fff;
            }
            .nav-item {
              font-weight: 600;
            }
          }
          .label {
            position: absolute;
            top: 10px;
            right: -2px;
            padding: 0 4px;
            height: 16px;
            border-radius: 12px;
            color: #fff;
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            line-height: 16px;
            &.new {
              background-color: #22cf33;
            }
            &.hot {
              background-color: #e8673e;
            }
            i {
              display: inline-block;
              transform: scale(0.8);
            }
          }
          &:hover {
            &::after {
              border-bottom: 4px solid #fff;
            }
          }
          &.ant-menu-item-active {
            &::after {
              border-bottom: 4px solid #fff;
            }
          }
        }
        ::v-deep(.ant-menu-submenu) {
          &-active,
          &-open {
            color: #fff;
          }
          &-title {
            &:hover {
              color: #fff;
            }
            .anticon-ellipsis {
              font-size: 25px !important;
              transform: translateY(5px);
            }
          }
        }
      }
      ::v-deep(.ant-menu-horizontal:not(.ant-menu-dark)) {
        & > .ant-menu-item {
          margin-top: 0;
        }
        & > .ant-menu-submenu:hover {
          color: #fff;
        }
        & > .ant-menu-submenu:hover::after {
          border-bottom: none;
        }
      }
    }
    .info {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      .mixinFlex(flex-end; center);
      .user {
        .mixinFlex(flex-end; center);
        position: relative;
        cursor: pointer;
        .icon {
          font-size: 20px;
          color: #fff;
        }
        .user-box {
          z-index: 10000;
          position: absolute;
          right: 0;
          top: 0;
          padding-top: 30px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s;
          transform: scaleY(0.8);
          transform-origin: 0 0;
          ul {
            width: 100px;
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(148, 148, 148, 0.1);
            border-radius: 4px;
            padding: 10px 0;
            li {
              height: 32px;
              padding: 6px 20px;
              font-size: 14px;
              color: #666;
              .mixinFlex(flex-start; center);
              cursor: pointer;
              span {
                margin-right: 12px;
                font-size: 16px;
              }
              i {
                font-style: normal;
              }
              &:hover {
                color: @color-theme;
              }
            }
          }
        }
        &:hover {
          .user-box {
            opacity: 1;
            transform: scaleY(1);
            visibility: initial;
          }
        }
      }
    }
  }
}
</style>
